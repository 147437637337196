import { defineStore } from 'pinia'

interface State {
  menu: any[]
  activeItem: string
  activeSubItem: string
  openByDefault?: string
}

export const useMenuStore = defineStore('menu', {
  state: (): State => ({
    menu: [],
    activeItem: '',
    activeSubItem: '',
    openByDefault: null,
  }),
  actions: {
    setMenu(menu: any[]) {
      this.menu = menu
    },
    setActiveItem(activeItem: string) {
      this.activeItem = activeItem
    },
    setActiveSubItem(activeSubItem: string) {
      this.activeSubItem = activeSubItem
    },
    setOpenByDefault(openByDefault: string) {
      this.openByDefault = openByDefault
    },
  },
})
